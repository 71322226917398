/** @jsxImportSource @emotion/react */
import { IntroContainer, Text, Button, ButtonTitle, IntroText, Tip, FadeIn } from '../styles/intro';

interface Prop {
  goNextStep: () => void;
}

function Intro(props: Prop) {
  const { goNextStep } = props;

  return (
    <IntroContainer>
      <IntroText>
        떨어지는 벚꽃잎을 잡으면
        <br />
        행운이 찾아와요!
      </IntroText>
      <Text animationDelay={3} paddingBottom={20}>
        벚꽃구경.. 올해도 혼자 가실 건가요?!
        <br />
        떨어지는 벚꽃잎을 잡고 누구와 함께 가면
        <br /> 좋을지 알아봐요!
      </Text>
      <Text animationDelay={4} paddingBottom={80}>
        혹시 모르죠 인연을 만들어줄지도..후후
        <br />
        님도 보고 뽕도 따고 :)
      </Text>
      <FadeIn>
        <Button type="button" onClick={goNextStep}>
          <ButtonTitle>선택하러가기!!</ButtonTitle>
        </Button>
        <Tip>
          함께 벚꽃구경 가고싶은 사람의
          <br />
          프로필을 선택하세요!
        </Tip>
      </FadeIn>
    </IntroContainer>
  );
}

export default Intro;

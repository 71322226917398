import { loadScript } from '../libs/misc';

declare global {
  interface Window {
    Kakao: {
      init: (sdkKey: string) => void;
      Auth: {
        login: ({
          scope,
          success,
          fail,
        }: {
          scope?: string;
          success: (response: any) => void;
          fail: (response: any) => void;
        }) => any;
        logout: any;
        getAccessToken: any;
        setAccessToken: any;
      };
      API: {
        request: ({
          url,
          success,
          fail,
          data,
        }: {
          url: string;
          success: (res: any) => void;
          fail: (error: any) => void;
          data?: any;
        }) => Promise<any>;
      };
      Link: {
        sendScrap: ({
          requestUrl,
          installTalk,
          templateId,
          templateArgs,
        }: {
          requestUrl: string;
          installTalk: boolean;
          templateId?: number;
          templateArgs?: any;
        }) => void;
      };
      isInitialized: () => boolean;
    };
  }
}

const KAKAO_SDK_KEY = '5cca10e4245ae9427948397cc5996aa1';

export interface KakaoTokenResp {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_token_expires_in: number;
  scope: string;
  token_type: string;
}

export interface KakaoFriendList {
  profile_nickname: string;
  profile_thumbnail_image: string;
  allowed_msg: boolean;
  id: number;
  uuid: string;
  favorite: boolean;
}

export interface KakaoFriendListResp {
  elements: KakaoFriendList[];
  total_count: number;
  after_url: string | null;
  favorite_count: number;
}

export const init = () => {
  const source = '//developers.kakao.com/sdk/js/kakao.min.js';
  loadScript(source).then(() => {
    if (window.Kakao?.isInitialized()) return;
    window.Kakao.init(KAKAO_SDK_KEY);
  });
};

/**
 * @desc [카카오로그인] 사용자가 카카오앱에 로그인할 수 있도록 로그인 팝업창을 띄우는 함수
 */
export const tokenLogin = () => {
  return new Promise<KakaoTokenResp>((resolve, reject) => {
    window.Kakao.Auth.login({
      // 로그인 성공 시 토큰 전달하는 콜백 함수
      success: (res) => {
        resolve(res);
      },
      // 로그인이 실패할 경우 에러를 전달하는 콜백 함수
      fail: (err) => {
        reject(err);
      },
    });
  });
};

/**
 * @desc 현재 카카오앱에 로그인되어 있는 사용자를 로그아웃시키고, Access Token을 삭제하는 함수
 */
export const logout = () => {
  return new Promise<void>((resolve, reject) => {
    window.Kakao.Auth.logout(function () {
      resolve();
    });
  });
};

/**
 * @desc 카카오앱 토큰 값을 가져오는 함수
 */
export const getAccessToken = () => {
  return new Promise<string>((resolve, reject) => {
    resolve(window.Kakao.Auth.getAccessToken());
  });
};

/**
 * @desc 카카오 스크랩 링크 공유하는 함수
 */
export const sendScrap = ({ url }: { url: string }) => {
  return Promise.resolve<void>(
    window.Kakao.Link.sendScrap({
      requestUrl: url,
      installTalk: true,
    })
  );
};

/**
 * @desc 카카오 내 이름 가져오는 함수
 */
export const getUserName = () => {
  return new Promise<{ nickName: string }>((resolve, reject) => {
    window.Kakao.API.request({
      url: '/v1/api/talk/profile',
      success: (res) => {
        resolve(res);
      },
      fail: (err) => {
        reject(err);
      },
    });
  });
};

import styled from '@emotion/styled';
import background from '../assets/background.png';
import result from '../assets/result.png';

export const ResultContainer = styled.div`
  display: flex;
  position: relative;
  max-width: 500px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  color: #372e2b;
  justify-content: center;
  font-size: 35px;
  overflow: hidden;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

export const ResultButton = styled.button`
  border-radius: 25px;
  width: 220px;
  border: none;
  background-color: #ffb8e3;
  padding: 4px;
  display: flex;
  cursor: pointer;
  box-shadow: 1px 1px 30px rgb(0, 0, 0, 0.15);
  text-decoration: none;
  color: white;
  margin-bottom: 15px;
`;

export const ResultButtonText = styled.span`
  font-size: 21px;
  padding: 3px 0px 9px;
  border: 1px solid #ffffff;
  border-radius: 25px;
  flex: 1;
`;

export const ResultDiv = styled.div`
  width: 293px;
  height: 324px;
  background-image: url(${result});
  background-repeat: no-repeat;
  background-size: contain;
  white-space: pre;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
`;

interface ResultTextProp {
  padding?: number;
  fontSize?: number;
}

export const ResultText = styled.p<ResultTextProp>`
  color: white;
  padding-bottom: ${(prop) => prop.padding || 0}px;
  font-size: ${(prop) => prop.fontSize || 21}px;
`;

export const CherryBlossom = styled.img`
  position: absolute;
  right: 0px;
  top: 15%;
  transform: rotate(15deg);
`;

/** @jsxImportSource @emotion/react */
import { Button, Input, FriendInputListItem } from '../styles/select';

interface Prop {
  index: number;
  friend: string;
  handleFriendList: (index: number, friend: string) => void;
  deleteFriend: (index: number) => void;
}

function InputFriend(props: Prop) {
  const { index, friend, handleFriendList, deleteFriend } = props;

  return (
    <FriendInputListItem>
      <Input type="text" onChange={(e) => handleFriendList(index, e.target.value as string)} value={friend} />
      {Boolean(index) && <Button onClick={() => deleteFriend(index)}>삭제하기</Button>}
    </FriendInputListItem>
  );
}

export default InputFriend;

/** @jsxImportSource @emotion/react */
import title from '../assets/title.svg';
import subTitle from '../assets/sub_title.svg';
import cherryBlossom from '../assets/cherry_blossom.png';
import {
  MainContainer,
  Title,
  SubTitle,
  StartPhrase,
  MainButton,
  MainButtonTitle,
  CherryBlossom,
} from '../styles/main';

interface Prop {
  goNextStep: () => void;
}

function Main(props: Prop) {
  const { goNextStep } = props;
  return (
    <MainContainer>
      <CherryBlossom src={cherryBlossom} />
      <Title src={title} alt="메인 타이틀" />
      <SubTitle src={subTitle} alt="메인 서브 타이틀" />
      <StartPhrase>나랑 벚꽃 구경 갈래?</StartPhrase>
      <MainButton type="button" onClick={goNextStep}>
        <MainButtonTitle>시작하기</MainButtonTitle>
      </MainButton>
    </MainContainer>
  );
}

export default Main;

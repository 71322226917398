/** @jsxImportSource @emotion/react */
import { useRef, useState, MouseEvent } from 'react';
import { CatchContainer, CatchMotion, Leaf, CatchProps, Content } from '../styles/catch';
import leaf1 from '../assets/leaf_1.svg';
import leaf2 from '../assets/leaf_2.svg';
import leaf3 from '../assets/leaf_3.svg';
import leaf4 from '../assets/leaf_4.svg';
import { useNavigate } from 'react-router-dom';

interface Prop {
  friendList: string[];
  selectFriend: (friend: string) => void;
}

function Catch(props: Prop) {
  const { friendList, selectFriend } = props;
  const catchContainer = useRef(null);
  const [isCatched, setIsCatched] = useState(false);
  const navigate = useNavigate();
  const [catchMotionState, setCatchModionState] = useState<CatchProps>({
    x: 0,
    y: 0,
    animationState: 'paused',
    display: 'none',
  });

  const catchLeaf = (e: MouseEvent, name: string) => {
    if (isCatched) {
      return;
    }
    setIsCatched(true);
    selectFriend(name);
    setCatchModionState({
      x: e.nativeEvent.pageX - 100,
      y: e.nativeEvent.pageY - 100,
      animationState: 'running',
      display: 'block',
    });
    window.setTimeout(() => {
      navigate(`/result?name=${name}`);
    }, 4000);
  };

  const setLeaves = () => {
    const leaves = [leaf1, leaf2, leaf3, leaf4];
    return friendList.map((friend, index) => {
      const delay = Math.random() * 4;
      const duration = Math.floor(Math.random() * 5) + 8;
      const startAt = Math.floor(Math.random() * 100) * (index % 2 ? 1 : -1);
      const endAt = Math.floor(Math.random() * 100) * (new Date().getTime() + (1 % 2) ? 1 : -1);
      const startDeg = Math.floor(Math.random() * 180) * (new Date().getTime() % 2 ? 1 : -1);
      const viaDeg = Math.floor(Math.random() * 180) * (new Date().getTime() % 2 ? 1 : -1);
      const endDeg = Math.floor(Math.random() * 180) * (new Date().getTime() % 2 ? 1 : -1);
      const leaf = leaves[Math.floor(Math.random() * 4)];
      const size = Math.ceil(Math.random() * 30) + 20;
      return (
        <Leaf
          key={friend}
          startAt={startAt}
          endAt={endAt}
          startDeg={startDeg}
          viaDeg={viaDeg}
          endDeg={endDeg}
          delay={delay}
          duration={duration}
          leaf={leaf}
          size={size}
          onClick={(event) => catchLeaf(event, friend)}
        />
      );
    });
  };

  const content = () => {
    if (isCatched) {
      return '잡았다!';
    }
    return '떨어지는 벚꽃잎을 잡아라!\n나의 님은 누구~?';
  };

  return (
    <CatchContainer ref={catchContainer}>
      <Content>{content()}</Content>
      {setLeaves()}
      <CatchMotion {...catchMotionState} />
    </CatchContainer>
  );
}

export default Catch;

/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import InputFriend from '../components/InputFriend';
import { SelectContainer, SelectFriendDiv, FriendInputList, Button } from '../styles/select';

interface Prop {
  goNextStep: () => void;
  setFriends: (friends: string[]) => void;
}

function Select(props: Prop) {
  const [friendList, setFriendList] = useState<string[]>(['']);
  const { goNextStep, setFriends } = props;

  const complete = () => {
    const result = friendList.filter((friend) => friend.length > 0);
    if (!result?.length) {
      return window.alert('친구를 입력해주세요.');
    }
    setFriends(result);
    goNextStep();
  };

  const handleFriendList = (index: number, name: string) => {
    const newFriendList = friendList.map((friend, friendIndex) => (index === friendIndex ? name : friend));
    setFriendList(newFriendList);
  };

  const deleteFriend = (index: number) => {
    const exceptForIndex = friendList.filter((friend, friendIndex) => friendIndex !== index);
    setFriendList(exceptForIndex);
  };

  const addList = () => {
    setFriendList([...friendList, '']);
  };

  return (
    <SelectContainer>
      <SelectFriendDiv>
        <p>친구 입력</p>
        <FriendInputList>
          {friendList.map((friend, index) => (
            <InputFriend
              key={index}
              index={index}
              friend={friend}
              handleFriendList={handleFriendList}
              deleteFriend={deleteFriend}
            />
          ))}
        </FriendInputList>
        <Button color={'#66a1ff'} onClick={addList}>
          추가하기
        </Button>
        <Button onClick={complete}>입력 완료!</Button>
      </SelectFriendDiv>
    </SelectContainer>
  );
}

export default Select;

import {
  CherryBlossom,
  ResultButton,
  ResultButtonText,
  ResultContainer,
  ResultDiv,
  ResultText,
} from '../styles/result';
import cherryBlossom from '../assets/cherry_blossom.png';
import { useNavigate } from 'react-router-dom';
import { getAccessToken, getUserName, sendScrap, tokenLogin } from '../helpers/kakao';
interface Prop {
  selectedFriend: string;
}

function Result(props: Prop) {
  const { selectedFriend } = props;
  const navigate = useNavigate();
  const sendInvitation = async () => {
    const accessToken = await getAccessToken();
    if (!accessToken) {
      await tokenLogin();
    }
    getUserName().then((response) => {
      const { nickName } = response;
      sendScrap({ url: `https://blossomwith.me/share?from=${nickName}` });
    });
  };

  return (
    <ResultContainer>
      <CherryBlossom src={cherryBlossom} />
      <ResultDiv>
        <ResultText padding={30} fontSize={35}>{`${selectedFriend}님과\n 벚꽃구경 어떠세요?`}</ResultText>
        <ResultText>{`${selectedFriend}님께 카카오톡으로\n 벚꽃구경 초대장을 전송해보세요!`}</ResultText>
      </ResultDiv>
      <ResultButton onClick={sendInvitation}>
        <ResultButtonText>초대장 보내기</ResultButtonText>
      </ResultButton>
      <ResultButton onClick={() => navigate('/')}>
        <ResultButtonText>다시잡기</ResultButtonText>
      </ResultButton>
    </ResultContainer>
  );
}

export default Result;

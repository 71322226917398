/**
 * 외부 스크립트 로더
 * @param url
 */
export function loadScript(url: string) {
  return new Promise<HTMLScriptElement>((resolve, reject) => {
    try {
      let script = document.querySelector(`script[src="${url}"]`) as HTMLScriptElement;
      if (!script) {
        script = document.createElement('script');
        script.setAttribute('src', url);
        script.setAttribute('async', 'true');
        script.setAttribute('defer', 'true');
        script.setAttribute('type', 'text/javascript');
      } else if (script.hasAttribute('data-loaded')) {
        // script 태그가 생성됐고, 이벤트도 등록된 경우
        resolve(script);
      }
      // 이벤트 등록
      script.addEventListener('error', (event) => reject(event));
      script.addEventListener('abort', (event) => reject(event));
      script.addEventListener('load', () => {
        script.setAttribute('data-loaded', 'true');
        resolve(script);
      });
      document.body.appendChild(script);
    } catch (e) {
      reject(e);
    }
  });
}

import {
  CherryBlossom,
  ResultButton,
  ResultButtonText,
  ResultContainer,
  ResultDiv,
  ResultText,
} from '../styles/result';
import cherryBlossom from '../assets/cherry_blossom.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Share() {
  const [searchParams] = useSearchParams();
  const [fromName, setFromName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const name = searchParams.get('from');
    name && setFromName(name);
  });
  return (
    <ResultContainer>
      <CherryBlossom src={cherryBlossom} />
      <ResultDiv>
        <ResultText padding={30} fontSize={35}>
          {'나랑같이\n벚꽃구경 갈래?'}
        </ResultText>
        <ResultText>{`${fromName}님께서\n벚꽃구경 초대장을 보냈어요!`}</ResultText>
      </ResultDiv>
      <ResultButton onClick={() => navigate('/')}>
        <ResultButtonText>나도 벚꽃잎 잡아볼래!</ResultButtonText>
      </ResultButton>
    </ResultContainer>
  );
}

export default Share;

import styled from '@emotion/styled';
import background from '../assets/main_background.png';

export const MainContainer = styled.div`
  display: flex;
  position: relative;
  max-width: 500px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

export const Title = styled.img`
  margin-top: calc(40vh - 139px);
`;

export const SubTitle = styled.img`
  margin-top: 20px;
`;

export const StartPhrase = styled.span`
  bottom: 180px;
  position: absolute;
  font-size: 30px;
  color: #372e2b;
`;

export const MainButton = styled.button`
  border-radius: 25px;
  width: 220px;
  border: none;
  bottom: 100px;
  position: absolute;
  background-color: #ffb8e3;
  padding: 4px;
  display: flex;
  cursor: pointer;
  box-shadow: 1px 1px 30px rgb(0, 0, 0, 0.15);
  text-decoration: none;
  color: white;
`;

export const MainButtonTitle = styled.span`
  font-size: 30px;
  padding: 3px 66px 9px;
  border: 1px solid #ffffff;
  border-radius: 25px;
  flex: 1;
`;

export const CherryBlossom = styled.img`
  position: absolute;
  right: 0px;
  top: 15%;
`;

import { Global } from '@emotion/react';
import reset from './Reset';
import './App.css';
import { init, getAccessToken, logout } from './helpers/kakao';
import { useEffect, useState } from 'react';
import Main from './pages/main';
import Intro from './pages/intro';
import Select from './pages/select';
import Catch from './pages/catch';
import Result from './pages/result';
import Share from './pages/share';
import { Route, Routes, useSearchParams } from 'react-router-dom';

function App() {
  const [step, setStep] = useState(0);
  const [friendList, setFriendList] = useState<string[]>([]);
  const [selectedFriend, setSelectedFriend] = useState('');
  const [searchParams] = useSearchParams();
  const goNextStep = () => {
    setStep((step) => step + 1);
  };

  const showView = (step: number) => {
    switch (step) {
      case 0:
        return <Main goNextStep={goNextStep} />;
      case 1:
        return <Intro goNextStep={goNextStep} />;
      case 2:
        return <Select goNextStep={goNextStep} setFriends={setFriendList} />;
      case 3:
        return <Catch friendList={friendList} selectFriend={setSelectedFriend} />;
    }
  };

  useEffect(() => {
    init();

    const getName = searchParams.get('name');
    getName && setSelectedFriend(getName);

    return () => {
      getAccessToken().then((res) => {
        if (res) {
          logout();
        }
      });
    };
  }, []);
  return (
    <div className="App">
      <Global styles={reset} />
      <Routes>
        <Route path="/" element={showView(step)} />
        <Route path="result" element={<Result selectedFriend={selectedFriend} />} />
        <Route path="share" element={<Share />} />
      </Routes>
    </div>
  );
}

export default App;

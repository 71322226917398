import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import background from '../assets/background.png';
import hand1 from '../assets/hand_1.svg';
import hand2 from '../assets/hand_2.svg';
import handCatch from '../assets/hand_catch.svg';

export type LeafProps = {
  startAt: number;
  endAt: number;
  startDeg: number;
  viaDeg: number;
  endDeg: number;
  delay: number;
  duration: number;
  leaf: string;
  size: number;
};

export const fall = (props: LeafProps) => keyframes`
  0% {
      opacity: 1;
      top: 0;
      margin-left: ${props.startAt}%;
      transform: rotate(${props.startDeg}deg);
  }
  40% {
      transform: rotate(${props.viaDeg}deg);
  }
  100% {
      opacity: 0.2;
      top: 100%;
      margin-left: ${props.endAt}%;
      transform: rotate(${props.endDeg}deg);
  }
`;

export const Leaf = styled.div<LeafProps>`
  background-image: url(${(props) => props.leaf});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  opacity: 0;
  animation: ${(props) => fall(props)} ${(props) => props.duration}s linear infinite;
  animation-delay: ${(props) => props.delay}s;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  z-index: 10;
  cursor: pointer;
`;

export const CatchContainer = styled.div`
  display: flex;
  position: relative;
  max-width: 500px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  color: #372e2b;
  justify-content: center;
  font-size: 35px;
  overflow: hidden;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

export type CatchProps = {
  x: number;
  y: number;
  animationState: 'paused' | 'running';
  display: string;
};

export const catchLeaf = keyframes`
  from {
    background-image: url(${hand1});
    width: 200px;
    height: 200px;
  }
  50% {
    background-image: url(${hand2});
    width: 200px;
    height: 200px;
  }
  100% {
    background-image: url(${handCatch});
    width: 300px;
    height: 300px;
  }
  `;

export const CatchMotion = styled.div<CatchProps>`
  position: absolute;
  display: ${(props) => props.display};
  width: 0px;
  height: 0px;
  top: ${(props) => props.y}px;
  left: calc(${(props) => props.x}px - (100vw - 500px) / 2);
  animation: ${catchLeaf} 4s;
  animation-play-state: ${(props) => props.animationState};
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 20;
  @media (max-width: 500px) {
    left: ${(props) => props.x}px;
  }
`;

export const Content = styled.p`
  white-space: pre;
  padding-bottom: 300px;
`;

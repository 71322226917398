import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import background from '../assets/background.png';

const fadeOut = keyframes`
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const IntroContainer = styled.div`
  display: flex;
  max-width: 500px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  color: #372e2b;
  justify-content: center;
  font-size: 35px;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

export type TextProps = {
  animationDelay: number;
  paddingBottom?: number;
};

export const Text = styled.p<TextProps>`
  font-size: 25px;
  line-height: 30px;
  padding-bottom: 20px;
  opacity: 0;
  animation: ${fadeIn} 2s ease;
  animation-fill-mode: forwards;
  animation-delay: ${(props) => props.animationDelay}s;
  padding-bottom: ${(props) => props.paddingBottom || 0}px;
`;

export const Button = styled.button`
  border-radius: 25px;
  width: 220px;
  border: none;
  background-color: #ffb8e3;
  padding: 4px;
  display: flex;
  cursor: pointer;
  box-shadow: 1px 1px 30px rgb(0, 0, 0, 0.15);
  text-decoration: none;
  color: white;
`;

export const IntroText = styled.p`
  opacity: 0;
  position: absolute;
  animation: ${fadeOut} 2s ease;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  font-size: 35px;
  line-height: 40px;
`;

export const FadeIn = styled.div`
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${fadeIn} 2s ease;
  animation-fill-mode: forwards;
  animation-delay: 5s;
`;

export const ButtonTitle = styled.span`
  font-size: 30px;
  padding: 3px 48px 9px;
  border: 1px solid #ffffff;
  border-radius: 25px;
  flex: 1;
`;

export const Tip = styled.p`
  font-size: 22px;
  line-height: 26px;
  padding-top: 20px;
`;

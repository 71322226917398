import styled from '@emotion/styled';
import background from '../assets/background.png';

export const SelectContainer = styled.div`
  display: flex;
  max-width: 500px;
  margin: auto;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  color: #372e2b;
  justify-content: center;
  font-size: 35px;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
`;

export const SelectFriendDiv = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 1px 1px 30px rgb(0, 0, 0, 0.2);
  min-width: 250px;
`;

export const Button = styled.button`
  font-size: 20px;
  padding: 2px 6px;
  margin: 0px;
  background-color: ${(props) => props.color || '#ffb8e3'};
  border-radius: 8px;
  border: 1px solid;
  color: white;
`;

export const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #a19d9d;
  height: 23px;
  padding: 2px 6px;
  margin-right: 2px;
  flex: 1;
  font-size: 16px;
`;

export const FriendInputList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
`;

export const FriendInputListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px 0px;
`;
